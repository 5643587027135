
import {mapState} from 'vuex'

export default {
  data() {
    return {
      show: false
    }
  },
  computed: {
    ...mapState(['pageScroll'])
  },
  watch:{
    pageScroll: function(){
      //Ajouter - le height du Header fix apres le bottom
      let haveShow = false
      if((this.$el?.getBoundingClientRect().top - window.innerHeight + 150) <= 0){
        this.show = true
        haveShow = true
      }
    }
  }
}
