
import { mapState, mapMutations } from "vuex";
import { Home } from "@/graphql/home";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";

export default {
  components: {
    VueSlickCarousel,
  },
  nuxtI18n: {
    paths: {
      "en-ca": "/",
      "fr-ca": "/",
    },
  },
  async asyncData({ app, i18n }) {
    const client = app.apolloProvider.defaultClient;
    const locale = i18n.locale.slice(0, 3) + i18n.locale.slice(3).toUpperCase();
    const newsResponse = await client.query({
      query: Home,
      variables: { locale: locale, limit: 2, sort: "Date:desc" },
    });

    const accueil = newsResponse.data.accueil.data.attributes;
    const actualites = newsResponse.data.actualites.data;

    return { accueil, actualites };
  },
  data() {
    return {
      accueil: {},
      show: false,
      anchorSelected: "",
      selectedTab: "",
      tabContent: false,
    };
  },
  head() {
    return {
      title: this.accueil.SEO.MetaTitle,
      meta: [
        {
          hid: "description",
          name: "description",
          content: this.accueil.SEO.MetaDescription,
        },
        {
          hid: "og:description",
          name: "og:description",
          content: this.accueil.SEO.MetaDescription,
        },
      ],
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    setTimeout(() => this.setPageAnimation("animBanner"), 500);
    this.selectedTab = "Expérience exceptionnelle";
    this.tabContent;
  },
  computed: {
    ...mapState(["pageScroll"]),
  },
  methods: {
    ...mapMutations({
      setPageAnimation: "SET_PAGE_ANIMATION",
    }),
    selectTab(tab) {
      this.selectedTab = tab === this.selectedTab ? "" : tab;
    },
  },
};
