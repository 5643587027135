
import {mapState} from 'vuex'

export default {
  data() {
    return {
      sticky: false
    }
  },
  computed: {
    ...mapState(['pageScroll'])
  },
  watch:{
    pageScroll: function(){
      //Ajouter - le height du Header fix apres le bottom
      let sticky = false
      if(this.$el?.getBoundingClientRect().top <= 0){
        this.sticky = true
      } else{
        this.sticky = false
      }
    }
  }
}
