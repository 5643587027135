
import {mapState} from 'vuex'

export default {
  data() {
    return {
      inView: false
    }
  },
  props:{
    oneWay: {
      type: Boolean,
      default: false
    },
    offset: {
      type: Number,
      default: 0
    }
  },
  computed: {
    ...mapState(['pageScroll'])
  },
  watch:{
    pageScroll: function(){
      //Ajouter - le height du Header fix apres le bottom
      let inView = false
      if((this.$el?.getBoundingClientRect().top  - this.offset)<= 90){
        this.inView = true
        this.$emit('inView')
      } else if(!this.oneWay){
        this.$emit('notInView')
        this.inView = false
      }
    }
  }
}
